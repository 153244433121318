import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { doctorActions } from "../../core/actions";
import BtnCellRenderer from "../common/BtnCellRenderer";
import {
  ROOM,
  ROOM_STATUS_BOOKED,
  ROOM_STATUS_AVAILABLE,
} from "../../shared/constants/index";
import { roomList } from "../../core/actions/roomMasterActions";
import { helperServices } from "../../shared/helper";
import Components from "./index";
import { format } from "date-fns";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
// import {Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { Alert } from "@mui/material";

function Room(props) {
  const gridRef = useRef();
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [roomData, setRoomData] = useState([]);
  const [assignedRoom, setAssignedRoom] = useState();
  const [patient, setPatient] = useState();
  const [reloadRoomData, setReloadRoomData] = useState(false);
  const [roomSelected, selectedRoom] = useState();
  const [roomsList, setRoomsList] = useState();
  const [roomAllocated, setAllocatedRoom] = useState();
  const [reloadAgGrid, setreloadAgGrid] = useState(false);
  const [openRoomModal, setOpenRoomModal] = useState(props.assignRoom);
  const [status, setStatus] = useState(undefined);
  var ServerResVal = ServerRes;
  const dispatch = useDispatch();
  const [columnDefs] = useState([
    {
      headerName: "Id",
      field: "id",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Room Name",
      field: "roomName",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      onGridReady: "open",
    },
    {
      headerName: "Floor Id",
      field: "floorId",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "Actions",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      width: 150,
      headerCheckboxSelection: false,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
  ]);
  const context = { componentParent: (props) => handleRendererCall(props) };

  useEffect(() => {
    if (props && props.patientInfo && props.patientInfo.visitId) {
      //setTimeout(() => {
      setPatient(props.patientInfo);
      //getAssignedRoom(props.patientInfo)
      // }, 20);
    }
    if (helperServices.CheckAccessLevel("InsurancePatients")) {
      setviewRoomDetails(true);
    }
  }, [props]);

  useEffect(() => {
    getRoomsList();
  }, []);
  const handleRendererCall = (props) => {
    console.log("room selecetd", props.data);
    let rooms = [];
    rooms = [...roomData];
    console.log("rooms:", rooms);

    rooms.forEach((element) => {
      if (element.id != props.data.id) {
        element.checked = false;
        element.assignedroom = false;
      }
      console.log("elemnt:", element);
    });

    setRoomData([...rooms]);
    selectedRoom({ ...props.data });
    setReloadRoomData(!reloadRoomData);
  };

  const bookRoom = (data) => {
    console.log("bookRoom 41 :", data);

    console.log("assignedRoom in book room :", assignedRoom);
    if (assignedRoom) {
      //checkout api
      console.log("assignedRoom in book room :", assignedRoom);
      let room = assignedRoom;
      room.roomStatus.uby = localStorage.getItem("name");
      room.roomStatus.cby = localStorage.getItem("name");

      room.roomStatus.bookstatus = ROOM_STATUS_AVAILABLE;
      dispatch(
        doctorActions.roomCheckout(room, (resCb) => {
          if (resCb) {
            console.log("assigned room value :", resCb);
            data.roomStatus.bookstatus = ROOM_STATUS_BOOKED;
            data.visitid = patient.visitId;
            dispatch(
              doctorActions.assignRoom(data, (resCb) => {
                if (resCb.successMessages) {
                  ServerResVal.success = "Room booked Successfully";
                  setServerRes({ ...ServerResVal });
                  props.closeRoomModal(ServerResVal);
                }
                if (resCb.errorMessages) {
                  ServerResVal.error = "Failed to allocate room";
                  setServerRes({ ...ServerResVal });
                  props.closeRoomModal(ServerResVal);
                }
                //getAssignedRoom(props.patientInfo);
                getRoomsList();
                console.log("ServerRes:", ServerRes);
              })
            );
          }
        })
      );
    } else {
      //book api assignRoom
      console.log("patient:", patient);
      data.visitid = patient.visitId;
      data.roomStatus.bookstatus = ROOM_STATUS_BOOKED;

      dispatch(
        doctorActions.assignRoom(data, (resCb) => {
          if (resCb) {
            console.log("assigned room value :", resCb);
          }
          if (resCb.successMessages) {
            ServerResVal.success = "Room booked Successfully";
            setServerRes({ ...ServerResVal });
            props.closeRoomModal(ServerResVal);
          }
          if (resCb.errorMessages) {
            ServerResVal.error = "Failed to allocate room";
            setServerRes({ ...ServerResVal });
            props.closeRoomModal(ServerResVal);
          }
          //getAssignedRoom(props.patientInfo);
          getRoomsList();
          console.log("ServerRes:", ServerRes);
        })
      );
    }
    // dispatch(doctorActions.getAssignedRoom(data,(resCb) => {
    //     if(resCb){
    //         console.log("assigned room value :",resCb);
    //     }
    // }));
  };
  const getAssignedRoom = (data, list) => {
    console.log("each data : ", data);
    if (data && data.visitId) {
      let patientData = data;
      patientData["visitid"] = patientData.visitId;
      dispatch(
        doctorActions.getAssignedRoom(data, (resCb) => {
          if (resCb) {
            if (resCb.room.id) {
              setAssignedRoom(resCb.room);
              console.log("assigned room type:", resCb.room.type);
              let rooms = roomData;
              list.push(resCb.room);
              console.log("rooms after gettiong assigned room:", list);
              //setRoomData([...rooms]);
              if (list) {
                list.forEach((item) => {
                  console.log("assigned room value :", resCb.room);
                  console.log("item :", item);
                  if (resCb.room && resCb.room.id === item.id) {
                    item["visitid"] = resCb.room.visitid;
                    item["assignedroom"] = true;
                    item["floorId"] = item.floorId;
                    item["status"] = item.roomStatus.bookstatus;
                  } else {
                    item["assignedroom"] = false;
                  }
                });
                console.log("updated list:", list);

                setRoomData([...list]);

                setReloadRoomData(!reloadRoomData);
              }

            //   setviewRoomDetails(true);

              let elm = document.getElementsByClassName(
                "ag-body-viewport ag-layout-normal"
              )[0];
              console.log("elm:", elm);

              // if(!viewRoomDetails){
              //     elm.style.pointerEvents = "none";
              // } else {
              //     elm.style.pointerEvents = "visible";
              // }
            }
          }
        })
      );
    }
  };

  const handleSelect = (event, name) => {
    console.log("room selcted:", name);
    let filterdRooms = roomData.filter((room) => room.type == name);
    setRoomsList(filterdRooms);
  };

  const assignSelectedRoom = (event) => {
    console.log("room allocated:", roomSelected);
    bookRoom(roomSelected);
  };

  const [viewRoomDetails, setviewRoomDetails] = useState(false);

  const getRoomsList = () => {
    let data = { facilityId: "", roomStatus: [] };
    dispatch(
      doctorActions.getRoomList(data, (resCb) => {
        if (resCb) {
          console.log("All Bed Details - Response", resCb);
          if (resCb.roomList) {
            dispatch(doctorActions.setPageHeader(ROOM));
            let roomList = Object.values(resCb.roomList);
            for (let item of roomList) {
              item["id"] = item.id;
              item["roomName"] = item.roomName;
              item["floorId"] = item.floorId;
              item["status"] = item.roomStatus.bookstatus
                ? item.roomStatus.bookstatus
                : "available";
              item["type"] = item.type;
              item["description"] = item.description;

              if (assignedRoom && assignedRoom.id === item.id) {
                item["visitid"] = item.visitId;
                item["assignedroom"] = true;
                console.log("room value :", item, assignedRoom);
                console.log(
                  " props.patientInfo.visitId :",
                  props.patientInfo.visitId
                );
                console.log("props.patientInfo.visitId :", item);
              } else {
                item["assignedroom"] = false;
              }
            }
            roomList = roomList.filter((r) => r.status == "available");
            setRoomData([...roomList]);
            console.log("roomList obtained:", roomList);
            //setReloadRoomData(!reloadRoomData);
            // console.log("roomdata obtained:",roomData);
            getAssignedRoom(props.patientInfo, roomList);
          }
        }
      })
    );
  };
  const toggleRoom = () => {
    setviewRoomDetails(!viewRoomDetails);
    let elm = document.getElementsByClassName(
      "ag-body-viewport ag-layout-normal"
    )[0];

    if (!viewRoomDetails) {
      elm.style.pointerEvents = "none";
    } else {
      elm.style.pointerEvents = "visible";
    }
  };

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  };

  // const selectAssigned = useCallback((assigned) => {
  //    if(assigned && gridRef.current){
  //     gridRef.current.api.forEachNode(function (node) {
  //         console.log("node:",node.data.id);
  //       node.setSelected(node.data.id === assigned.id);
  //     });
  //    }
  //    setReloadRoomData(!reloadRoomData)

  //   }, []);

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return (
        !!params.data &&
        params.data.roomStatus &&
        params.data.roomStatus.bookstatus != "closed"
      );
    };
  }, []);

  const onSelectionChanged = (e) => {
    let rooms = [...roomData];
    console.log("rooms:", rooms);

    // Check if any row is selected
    if (e.api.getSelectedRows().length > 0) {
      const selectedRow = e.api.getSelectedRows()[0];

      //   rooms.forEach(element => {
      //     if (element.id !== selectedRow.id) {
      //       element.checked = false;
      //       element.assignedroom = false;
      //     } else {
      //       element.checked = true;
      //       element.assignedroom = true;
      //       selectedRoom({...e.api.getSelectedRows()[0]});

      //     }
      //     console.log("elemnt:", element);
      //   });

      for (let i = 0; i < roomData.length; i++) {
        if (roomData[i].id !== selectedRow.id) {
          roomData[i].checked = false;
          roomData[i].assignedroom = false;
        } else {
          roomData[i].checked = true;
          roomData[i].assignedroom = true;
          selectedRoom({ ...e.api.getSelectedRows()[0] });
        }
        console.log("haii", roomData[i]);
        console.log("eee", roomData);
        //  console.log("gggg", getSelectedRows);
      }

      console.log("hiiiii", selectedRow);
      console.log("hqwi", roomSelected, roomData);
    }
    // Handle the case when no rows are selected
    else {
      // Clear the selection in your state or perform any necessary actions
      console.log("No rows selected");
    }
  };

  const getRowStyle = (params) => {
    if (
      params.data.visitid === null &&
      params.data.roomStatus &&
      params.data.roomStatus.bookstatus == "booked"
    ) {
      return { background: "lightgray" };
    }
  };

  const getRowClass = (params) => {
    if (
      params.data.visitid === null &&
      params.data.roomStatus &&
      params.data.roomStatus.bookstatus == "booked"
    ) {
      return "disable-row";
    }
  };

  function onRowDataChanged(e) {
    console.log("params", e);
    //var invkey=this.state.invkey
    //if(invkey)
    // {
    gridRef.current.api.forEachNode((node) => {
      if (node.data.assignedroom) {
        console.log("params", e);
        node.setSelected(true);
      }
    });
    //}
  }

  const closeRoomModal = () => {
    props.closeRoomModal();
  };
  const handleVisitSelect = (event) => {
    props.handlevisitChange(event);
  };

  const getFormateDate = (date) => {
    //  procedureDate = date;
    const reqdate = new Date(date);
    // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min;
    let formatedate = format(reqdate, "MMM dd yyyy");
    return formatedate;
  };

  return (
    <>
      <main className="">
        {ServerRes.success && (
          <Alert severity="success" className="alert-div">
            {ServerRes.success}

            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(event) => closeAlert(event)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Alert>
        )}

        {ServerRes.error && (
          <Alert theme="error" className="alert-div">
            {ServerRes.error}
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(event) => closeAlert(event)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Alert>
        )}

        {/* <h5 className="">
          Room Details
          {props.patientInfo &&
          props.patientInfo.status &&
          props.patientInfo.status === "open" ? (
            <>
              {!helperServices.CheckAccessLevel("InsurancePatients") ? (
                <span
                  className="ml-3"
                  onClick={toggleRoom}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="material-icons"
                    title={viewRoomDetails ? "Edit" : "View"}
                  >
                    {viewRoomDetails ? "edit" : "visibility"}
                  </i>
                </span>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </h5> */}
        {roomData && (
          <Dialog
            className="d-flex flex-column-reverse "
            id="room"
            open={openRoomModal}
            size="lg"
          >
            <div>
              <DialogTitle style={{marginLeft:"14px"}}>Room Details</DialogTitle>
              <DialogContent>
                <div className="w-50 ml-3 pb-0">
                  <span style={{ fontSize: "16px", fontWeight: "bold",marginLeft:"14px" }}>
                    Visit Date:{" "}
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    {getFormateDate(props.patientInfo.visitDate)}
                  </span>
                </div>
                <IconButton
                  aria-label="close"
                  onClick={(event) => closeRoomModal(event)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogContent>
              <DialogContent>
                {/* <div>
                        <Components.VisitList 
                        allVisitdates={props.allVisitdates}
                        selectedDate={props.selectedDate}
                        handleVisitSelect = {() => handleVisitSelect()} 
                        
                        />
                    </div> */}

                <div
                  className="ag-theme-balham ag-style px-3 py-4"
                  style={{ height: "300px" }}
                >
                  <AgGridReact
                    ref={gridRef}
                    frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    context={context}
                    rowData={roomData}
                    columnDefs={columnDefs}
                    rowSelection={"single"}
                    suppressRowClickSelection={false}
                    isRowSelectable={isRowSelectable}
                    onSelectionChanged={onSelectionChanged}
                    onRowDataChanged={onRowDataChanged}
                    getRowClass={getRowClass}
                    getRowStyle={getRowStyle}
                  ></AgGridReact>
                </div>
                <div className="d-flex justify-content-end mt-3 px-3">
                  <Button
                    className=" btn-large btn-primary"
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "capitalize" }}
                    onClick={(event) => assignSelectedRoom(event)}
                  >
                    Assign Room
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog>
        )}
        {/* {(viewRoomDetails && assignedRoom && assignedRoom.roomStatus) &&
              <div className='my-2 p-3 card' >
              <div className='px-2'>
                  <div className="ag-theme-balham ag-style rounded table mb-0 tcol"  >
                     
                       <div>
                          <div className="room-type d-flex" style={{pointerEvents:'none'}}>
                              <div className="btn btn-outline-info mr-2 active">
                                  {bookedRoomType}   
                              </div>
                          </div>
                      </div> 
                      <div className='mt-3 rooms-list'>
                          <ul className='list-group'>

                               <li className='list-group-item active' style={{pointerEvents:'none'}}>
                                  <h6 className='mb-1'>{assignedRoom.roomName}</h6>
                                  <p className='mb-1'>
                                      {assignedRoom.roomStatus.bookstatus}
                                  </p>
                                  <p className='mb-0'>{assignedRoom.description}</p>
                                  
                               </li>
                          </ul>
                      </div>
                     
                      </div>
                      </div> 
                   
             
                </div> 
                }
            {!viewRoomDetails && 
            <div className='my-2 p-3 card'>
                <div className='px-2'>
                    <div className="ag-theme-balham ag-style rounded table mb-0 tcol"  >
                       
                         <div>
                            <div className="room-type d-flex">
                            {roomTypes && roomTypes.map((item, idx) => (
                                <div key={idx} className={roomType == item ? 'btn btn-outline-info mr-2 active' : viewRoomDetails ? 'btn btn-outline-info mr-2 disable' :'btn btn-outline-info mr-2' } onClick={(event) => handleSelect(event,item)}>
                                    {item}   
                                </div>
                                ))}
                              
                            </div>
                        </div> 
                        <div className='mt-3 rooms-list'>
                            <ul className='list-group'>

                            {roomsList && roomsList.map((room, idx) => (
                                 <li className={roomSelected && roomSelected.id == room.id ? 'list-group-item active' : (room.status != 'available' || viewRoomDetails) ? 'list-group-item disable' : 'list-group-item'} onClick={(event) => selectRoom(event,room)}>
                                    <h6 className='mb-1'>{room.roomName}</h6>
                                    <p className='mb-1'>
                                        {room.roomStatus.bookstatus}
                                    </p>
                                    <p className='mb-0'>{room.description}</p>
                                    
                                 </li>
                                ))}
                            </ul>
                        </div>
                        {!viewRoomDetails &&
                        <div className='d-flex justify-content-end mt-3'>
                        <div className='btn btn-large btn-primary' onClick={(event) => assignRoom(event)}>Assign Room</div>
                        </div>
                        }
                        </div>
                        </div> 
                     
               
            </div>      
            }  */}
      </main>
    </>
  );
}

export default Room;
