import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../core/actions";
import { GET_UPLOAD_PATH } from "../../shared/constants";
import Card from "@material-ui/core/Card";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Select, { createFilter } from "react-select";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";

import Components from "./index";
//import { modalview } from 'react-ga';
import { format } from "date-fns";
import { setValidationMode } from "@jsonforms/core";
import { helperServices } from "../../shared/helper";
import { insuranceActions } from "../../core/actions";

function InsuranceProcedureListing(props) {
  const [mode, setMode] = useState("new");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedType, setSelectedType] = useState();
  const dispatch = useDispatch();
  const [insuranceLists, setinsuranceLists] = useState();
  const [insuranceImages, setinsuranceImages] = useState([]);
  const [companyname, setCompanyName] = useState([]);
  const [patientInfo, setpatientInfo] = useState({
    visitId: "",
    patientId: "",
    name: "",
    age: "",
    phno: "",
    id: "",
    address: "",
    adhar: "",
    age: "",
    area: "",
    bloodgroup: "",
    city: "Arkula",
    state: "Karnataka",
    lname: "",
    mname: "",
    fname: "",
    occupation: "",
    patientcode: "",
    pincode: "",
    admsnDate: "",
    type: "OutPatient",
    fileNo: "",
    srcRef: "",
    language: "",
    education: "",
    sociostatus: "",
    tokenNumber: "",
    deskNumber: "",
    comments: "",
    status: "",
    ehrid: "",
    erpCustId: "",
    altPhno: "",
    visitDate: "",
    registeredOn: "",
    regFeesPdfUrl: "",
    emrPhono: "",
  });
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [toggleBasic, setToggleBasic] = useState(false);
  const [toggleUpload, setToggleUpload] = useState(false);
  const [togglePreauthReqt, setTogglePreauthReqt] = useState(false);
  const [togglePreauthResp, setTogglePreauthResp] = useState(false);
  const [toggleClaimreqt, setToggleClaimreqt] = useState(false);
  const [toggleClaimAppove, setToggleClaimAppove] = useState(false);

  // const[option, setOption] = useState([
  //     'Basic','UploadDoc','PriorRequest','PriorResponse','InvoiceGen','ClaimApproval','BillClear'
  // ]);

  const [viewInsurance, setviewInsurance] = useState(true);
  const [openInsuranceModal, setOpenInsuranceModal] = useState(
    props.addInsurance
  );
  const [selectedDate, setSelectedDate] = useState();
  let resCb = "";

  useEffect(() => {
    console.log("INSURANCE SECTION", props);
    console.log("INSURANCE SECTION", props.patientInfo);
    let patientInfolocal = props.patientInfo;
    let patientId = props.patientInfo.patientId;
    let visitId = props.patientInfo.visitId;
    console.log("ALL Id:", patientId, visitId);
    const keys = Object.keys(patientInfo);
    const item = Object.keys(props.patientInfo);
    var itmData = { ...patientInfolocal };

    for (let itm of item) {
      for (let key of keys) {
        if (itm === key) {
          console.log("patientDetail : ", itmData[itm]);
          patientInfo[key] = itmData[itm];
        }
      }
    }
    // setpatientInfo(patientInfolocal);
    setSelectedDate(props.selectedDate);
  }, [props]);

  useEffect(() => {
    getInsMaster();
    console.log("props in insurance procedure list : ", props);
    let patientInfolocal = props.patientInfo;
    const keys = Object.keys(patientInfo);
    const item = Object.keys(props.patientInfo);
    var itmData = { ...patientInfolocal };

    for (let itm of item) {
      for (let key of keys) {
        if (itm === key) {
          console.log("patientDetail : ", itmData[itm]);
          patientInfo[key] = itmData[itm];
        }
      }
    }

    setTimeout(() => {
      getInsuranceList();
    }, 1000);
    console.log("infoinsurance", patientInfo);
  }, [props]);

  const getInsuranceList = () => {
    console.log("props in insurance procedure list : ", patientInfo);
    dispatch(
      patientActions.getInsuranceList(patientInfo, (resCb) => {
        if (resCb) {
          console.log("Insurance listss : ", resCb.insuranceList);
        }

        if (resCb.insuranceList) {
          let insure = resCb.insuranceList;
          const filteredData = insure.filter((item) => {
            return item.type == "InvoiceGen";
          });
          if (filteredData && filteredData.length > 0) {
            setShowClaimButton(true);
          }
          setinsuranceLists(insure);
          insure.forEach((insurance) => {
            if (insurance.type == "Basic") {
              setToggleBasic(true);
            }
            if (insurance.type == "UploadDoc") {
              setToggleUpload(true);
            }
            if (insurance.type == "Pre Auth Request") {
              setTogglePreauthReqt(true);
            }
            if (insurance.type == "Pre Auth Response") {
              setTogglePreauthResp(true);
            }
            if (insurance.type == "Claim Request") {
              setToggleClaimreqt(true);
            }
            if (insurance.type == "Claim Approval") {
              setToggleClaimAppove(true);
            }
          });
          console.log("lists:", insure);
        }
      })
    );
  };
  const getInsMaster = () => {
    dispatch(
      insuranceActions.getInsuranceMaster((resCb) => {
        if (resCb) {
          console.log("getInsuranceMaster", resCb.masterinsurancelist);

          console.log("response from api : ", resCb);
          let company = [];
          let companylist = resCb.masterinsurancelist;
          if (companylist && companylist != null) {
            for (let comp of companylist) {
              console.log("comp", comp.companyName);

              //   company.push(comp);
              //company.push(comp.id)
              companyname.push(comp);
            }
            console.log("companyname", companyname);
          }
        }
      })
    );
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("selectedOption", event.target.value);
    console.log("SELECTEDOPTION:", selectedOption);
  };

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
    console.log("EVnn", event.target.value);
    console.log("selected", selectedType);
  };

  const insuranceButtonClick = (value, event) => {
    console.log("Event is", event);
    console.log("EVnn", value);
    setSelectedType(value);
    console.log("selected", selectedType);
    if (event) {
      setMode("edit");
    }
  };

  const closeModal = (event) => {
    setSelectedType(false);
    // props.onClose(event);
    getInsuranceList();
    setShowModal(false);
  };

  const closeInsuranceModal = (event) => {
    props.onClose(event);
  };

  const getFormattedDate = (date) => {
    if (date == "") return;
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy");
    return formatteddate;
  };
  const toggleInsurance = () => {
    setviewInsurance(!viewInsurance);
  };

  const showImage = (data) => {
    console.log("showImage", data);
    console.log("insurancelist: ", insuranceLists);
    let lst = [];
    for (let index = 0; index < insuranceLists.length; index++) {
      if (insuranceLists[index].type == "UploadDoc") {
        console.log("index is : ", index, insuranceLists[index]);
        lst.push(insuranceLists[index]);
      }
    }
    setinsuranceImages(lst);
    for (let i = 0; i < lst.length; i++) {
      if (lst[i].uploadId == data) {
        console.log("element : ", lst[i].uploadId, data);
        setData(i);
      }
    }
    console.log("Images to view is : ", insuranceImages, lst, data);
    setShowModal(true);
    //setpreview(true)
  };

  const handlevisitSelect = (e) => {
    // setSelectedDate(e.target.value);
    // selectedDate = e.target.value
    console.log("handleVisitSelect", e);
    props.handlevisitChange(e);
  };

  const getFormateDate = (date) => {
    console.log("date passed:", date);
    if (date == "") return;
    const reqdate = new Date(date);
    let formatedate = format(reqdate, "MMM dd yyyy");
    return formatedate;
  };

  return (
    <>
      <main className="">
        <Card className="p-3 my-4 mx-3">
          <div className="position-relative insurance">
            <span className="d-flex align-items-baseline justify-content-between">
              <span className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-baseline px-0">
                <h4 className="mb-1  pl-0">Insurance&nbsp;</h4>
                <p style={{ fontSize: "12px" }}>
                  {" "}
                  ({props.patientInfo.type}, &nbsp;
                  {getFormattedDate(props.patientInfo.visitDate)})
                </p>
              </span>
              {/* <span className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                <Components.VisitList
                  allVisitdates={props.allVisitdates}
                  selectedDate={props.selectedDate}
                  handlevisitChange={(e) => handlevisitSelect(e)}
                />
              </span> */}
            </span>

            {insuranceLists && insuranceLists.length > 0 ? (
              <div className="d-flex m-3 align-items-baseline filter-options">
                <i title="Filter" class="material-icons mx-3">
                  filter_list
                </i>

                <div className=" mx-3">
                  <select
                    value={selectedOption}
                    name="selectType"
                    className="w-100 form-control"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  >
                    <option defaultValue={"selected Type"}>Select Type</option>
                    <option value="Basic">Basic</option>
                    <option value="UploadDoc">UploadDoc</option>
                    <option value="Pre Auth Request">Pre Auth Request</option>
                    <option value="Pre Auth Response">Pre Auth Response</option>
                    <option value="InvoiceGen">Invoice</option>
                    <option value="Claim Request">Claim Request</option>
                    <option value="Claim Approval">Claim Approve</option>
                    <option value="BillClear">Bill Clear</option>
                  </select>
                </div>
                <div className="mx-3 ">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="select date"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  ></input>
                </div>
                <div className="mx-3 printAction">
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      marginLeft: "2px",
                      height: "33px",
                      borderRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    id="bornon"
                    variant="contained"
                    color="primary"
                    aria-controls="print-menu"
                    aria-haspopup="true"
                    style={{
                      height: "35px",
                      borderBottomLeftRadius: "3px",
                      borderTopLeftRadius: "3px",
                    }}
                  >
                    ADD
                  </Button>

                  <Select
                    id="dr"
                    className="printAction isurancetest"
                    style={{
                      height: "35px",
                    }}
                  >
                    <MenuItem
                      className="insurance-test"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => insuranceButtonClick("Basic")}
                        sx={{ textTransform: "capitalize" }}
                      >
                        Add Basic Info
                      </Button>
                    </MenuItem>

                    {toggleBasic && (
                      <MenuItem onClick={(e) => e.stopPropagation()}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() =>
                            insuranceButtonClick("Upload Document")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Upload Document
                        </Button>
                      </MenuItem>
                    )}

                    {toggleBasic && (
                      <MenuItem onClick={(e) => e.stopPropagation()}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() =>
                            insuranceButtonClick("Pre Auth Request")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Pre Auth Request
                        </Button>
                      </MenuItem>
                    )}

                    {toggleBasic && (
                      <MenuItem onClick={(e) => e.stopPropagation()}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() =>
                            insuranceButtonClick("Pre Auth Response")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Pre Auth Response
                        </Button>
                      </MenuItem>
                    )}
                    {showClaimButton && toggleBasic && (
                      <MenuItem onClick={(e) => e.stopPropagation()}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => insuranceButtonClick("Claim Request")}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Claim Request
                        </Button>
                      </MenuItem>
                    )}

                    {showClaimButton && toggleBasic && (
                      <MenuItem onClick={(e) => e.stopPropagation()}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => insuranceButtonClick("Claim Approval")}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Claim Approval
                        </Button>
                      </MenuItem>
                    )}
                  </Select>
                </div>
              </div>
            ) : (
              ""
            )}

            {insuranceLists && insuranceLists.length > 0 ? (
              <div className="form-group pt-3 pb-3 mb-0">
                {insuranceLists &&
                  insuranceLists.map((insurance, index) => (
                    <>
                      <div className="d-flex flex-column px-4 insurance-section mb-3">
                        {insurance.iName &&
                          insurance.policyNum &&
                          (selectedOption === "" ||
                            selectedOption === "Basic") && (
                            <div
                              className="d-flex align-items-end"
                              style={{
                                gap: "10px",
                                marginBottom: "16px",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                className="card insurancecard"
                               
                              >
                                {/* <div
                                  style={{
                                    position: "absolute",
                                    top: "10px", // Adjust spacing from the top of the card
                                    right: "10px", // Align icons to the right
                                    display: "flex",
                                    gap: "8px", // Space between icons
                                  }}
                                > */}
                                  <i
                                    className="material-icons"
                                    title="Edit"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "24px", // Size of the icon
                                      color: "#333", // Icon color
                                    }}
                                    onClick={() =>
                                      insuranceButtonClick("Basic", insurance)
                                    }
                                  >
                                    edit
                                  </i>

                                  {/* <i
                                    className="material-icons"
                                    title="Delete"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "24px", 
                                      color: "#333", 
                                    }}
                                    onClick={() =>
                                      console.log(
                                        `Delete ${insurance.policyNum}`
                                      )
                                    }
                                  >
                                    delete
                                  </i>
                                </div> */}

                                {/* Card Content */}
                                <div className="d-flex flex-column align-items-start insurance-wrap">
                                  <p
                                    style={{
                                      margin: 0,
                                      fontSize: "14px",
                                      whiteSpace: "normal", // Allow text to wrap normally
                                    }}
                                  >
                                    <strong>{insurance.iName}</strong> holds a
                                    policy with Policy Number{" "}
                                    <strong>{insurance.policyNum}</strong>.
                                  </p>
                                  {insurance.iOrg && (
                                    <p
                                      style={{
                                        margin: "4px 0",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Insurance is provided by{" "}
                                      <strong>
                                        {
                                          companyname.find(
                                            (options) =>
                                              options.id == insurance.iOrg
                                          )?.companyName
                                        }
                                      </strong>
                                    </p>
                                  )}
                                </div>
                                <span className="text-muted insuranceDate">
                                  {getFormattedDate(insurance.cdt)}
                                </span>
                              </div>
                            </div>
                          )}

                        {(insurance.uploadId && selectedOption === "") ||
                        (insurance.uploadId &&
                          selectedOption === "UploadDoc") ? (
                          <div
                            className="d-flex align-items-end"
                            style={{
                              gap: "10px",
                              marginBottom: "16px",
                            }}
                          >
                            <div className="card insurancecard" style={{ position: "relative" }}>
                              {/* <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  display: "flex",
                                  gap: "8px",
                                }}
                              > */}
                                <i
                                  className="material-icons"
                                  title="Edit"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                  onClick={(event) =>
                                    showImage(insurance.uploadId)
                                  }
                                >
                                  edit
                                </i>

                                {/* <i
                                  className="material-icons"
                                  title="Delete"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                  onClick={() =>
                                    console.log(`Delete ${insurance.policyNum}`)
                                  }
                                >
                                  delete
                                </i>
                              </div> */}

                              <div className="d-flex flex-column align-items-start">
                                <img
                                  src={GET_UPLOAD_PATH + insurance.imgurl}
                                  className="pres-img"
                                  style={{ cursor: `pointer` }}
                                ></img>
                              </div>
                              <span className="text-muted insuranceDate">
                                {getFormattedDate(insurance.cdt)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(insurance.type === "Pre Auth Request" &&
                          selectedOption === "") ||
                        (insurance.type === "Pre Auth Request" &&
                          selectedOption === "Pre Auth Request") ? (
                          <div
                            className="d-flex align-items-end"
                            style={{
                              gap: "10px",
                              marginBottom: "16px",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="card insurancecard">
                              {insuranceLists
                                .slice(index + 1)
                                .every(
                                  (item) =>
                                    item.type !== "Pre Auth Request" &&
                                    item.type !== "Pre Auth Response" &&
                                    item.type !== "Claim Request" &&
                                    item.type !== "Claim Response"
                                ) && (
                                <i
                                  className="material-icons insuranceIcon"
                                  title="Edit"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px", // Size of the icon
                                    color: "#333", // Icon color
                                  }}
                                  onClick={(event) =>
                                    insuranceButtonClick(
                                      "Pre Auth Request",
                                      insurance
                                    )
                                  }
                                >
                                  edit
                                </i>
                              )}
                              {/* <div
                                style={{
                                  position: "absolute",
                                  top: "10px", // Adjust spacing from the top of the card
                                  right: "10px", // Align icons to the right
                                  display: "flex",
                                  gap: "8px", // Space between icons
                                }}
                              > */}
                                {insuranceLists
                                  .slice(index + 1)
                                  .every(
                                    (item) =>
                                      item.type !== "Pre Auth Request" &&
                                      item.type !== "Pre Auth Response" &&
                                      item.type !== "Claim Request" &&
                                      item.type !== "Claim Response"
                                  ) && (
                                  <i
                                    className="material-icons insuranceIcon"
                                    title="Edit"
                                    onClick={(event) =>
                                      insuranceButtonClick(
                                        "Pre Auth Request",
                                        insurance
                                      )
                                    }
                                  >
                                    edit
                                  </i>
                                )}

                                {/* <i
                                  className="material-icons"
                                  title="Delete"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px", 
                                    color: "#333", 
                                  }}
                                  onClick={() =>
                                    console.log(`Delete ${insurance.policyNum}`)
                                  }
                                >
                                  delete
                                </i>
                              </div> */}

                              <div className="d-flex flex-column align-items-start insurance-wrap">
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "14px",
                                    whiteSpace: "normal", // Allow text to wrap normally
                                  }}
                                >
                                  The pre-authorization request for '
                                  {insurance.description}' for an amount of Rs.{" "}
                                  {insurance.amount}
                                </p>
                              </div>
                              <span className="text-muted insuranceDate">
                                {getFormattedDate(insurance.cdt)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(insurance.type === "Pre Auth Response" &&
                          selectedOption === "") ||
                        (insurance.type === "Pre Auth Response" &&
                          selectedOption === "Pre Auth Response") ? (
                          <div
                            className="d-flex align-items-end"
                            style={{
                              gap: "10px",
                              marginBottom: "16px",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="card insurancecard">
                              {/* <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  display: "flex",
                                  gap: "8px",
                                }}
                              > */}
                                {insuranceLists
                                  .slice(index + 1)
                                  .every(
                                    (item) =>
                                      item.type !== "Pre Auth Request" ||
                                      item.type !== "Pre Auth Response" ||
                                      item.type !== "Claim Request" ||
                                      item.type !== "Claim Response"
                                  ) && (
                                  <i
                                    className="material-icons insuranceIcon"
                                    title="Edit"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "24px", // Size of the icon
                                      color: "#333", // Icon color
                                    }}
                                    onClick={(event) =>
                                      insuranceButtonClick(
                                        "Pre Auth Response",
                                        insurance
                                      )
                                    }
                                  >
                                    edit
                                  </i>
                                )}

                                {/* <i
                                  className="material-icons"
                                  title="Delete"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                  onClick={(event) => markForDel(insurance, event)}
                                >
                                  delete
                                </i>
                              </div> */}
                              <div className="d-flex flex-column align-items-start insurance-wrap">
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "14px",
                                    whiteSpace: "normal", // Allow text to wrap normally
                                  }}
                                >
                                  The pre-authorization response approves '
                                  {insurance.description}' for an amount of Rs.{" "}
                                  {insurance.amount} {insurance.status}
                                </p>
                              </div>
                              <span className="text-muted insuranceDate">
                                {getFormattedDate(insurance.cdt)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(insurance.type === "Claim Request" &&
                          selectedOption === "") ||
                        (insurance.type === "Claim Request" &&
                          selectedOption === "Claim Request") ? (
                          <div
                            className="d-flex align-items-end"
                            style={{
                              gap: "10px",
                              marginBottom: "16px",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="card insurancecard" >
                              {/* <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  display: "flex",
                                  gap: "8px",
                                }}
                              > */}
                                <i
                                  className="material-icons"
                                  title="Edit"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                  onClick={(event) =>
                                    insuranceButtonClick(
                                      "Claim Request",
                                      insurance
                                    )
                                  }
                                >
                                  edit
                                </i>

                                {/* <i
                                  className="material-icons"
                                  title="Delete"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                  onClick={() =>
                                    console.log(`Delete ${insurance.policyNum}`)
                                  }
                                >
                                  delete
                                </i>
                              </div> */}
                              <div className="d-flex flex-column align-items-start insurance-wrap">
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "14px",
                                    whiteSpace: "normal", // Allow text to wrap normally
                                  }}
                                >
                                  The claim request for '{insurance.description}
                                  ' for an amount of Rs. {insurance.amount},
                                  associated with Invoice Number{" "}
                                  {insurance.invoiceNum}
                                </p>
                              </div>
                              <span
                                className="text-muted insuranceDate"
                                style={{ flexShrink: 0 }}
                              >
                                {getFormattedDate(insurance.cdt)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(insurance.type === "Claim Approval" &&
                          selectedOption === "") ||
                        (insurance.type === "Claim Approval" &&
                          selectedOption === "Claim Approval") ? (
                          <div
                            className="d-flex align-items-end"
                            style={{
                              gap: "10px",
                              marginBottom: "16px",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="card insurancecard">
                              {/* <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  display: "flex",
                                  gap: "8px",
                                }}
                              > */}
                                <i
                                  className="material-icons"
                                  title="Edit"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                  onClick={(event) =>
                                    insuranceButtonClick(
                                      "Claim Approval",
                                      insurance
                                    )
                                  }
                                >
                                  edit
                                </i>

                                {/* <i
                                  className="material-icons"
                                  title="Delete"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                  onClick={() =>
                                    console.log(`Delete ${insurance.policyNum}`)
                                  }
                                >
                                  delete
                                </i>
                              </div> */}
                              <div
                                className="d-flex flex-column align-items-start insurance-wrap"
                                style={{ minHeight: "30px" }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "14px",
                                    whiteSpace: "normal", // Allow text to wrap normally
                                  }}
                                >
                                  The claim for '{insurance.description}' for an
                                  amount of Rs. {insurance.amount} has been
                                  {insurance.status == "approve"
                                    ? " approved"
                                    : " denied"}
                                </p>
                              </div>

                              <span className="text-muted insuranceDate">
                                {getFormattedDate(insurance.cdt)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(insurance.type === "InvoiceGen" &&
                          selectedOption === "") ||
                        (insurance.type === "InvoiceGen" &&
                          selectedOption === "InvoiceGen") ? (
                          <div className="d-flex justify-content-between">
                            <div className="card insurancecard">
                              <div
                                className="d-flex flex-column align-items-start"
                                style={{ minHeight: "30px" }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "14px",
                                    whiteSpace: "normal", // Allow text to wrap normally
                                  }}
                                >
                                  Invoice Number {insurance.invoiceNum}{" "}
                                  generated on {insurance.invoiceDate}
                                </p>
                              </div>
                              <span className="text-muted insuranceDate">
                                {getFormattedDate(insurance.cdt)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(insurance.type === "BillClear" &&
                          selectedOption === "") ||
                        (insurance.type === "BillClear" &&
                          selectedOption === "BillClear") ? (
                          <div className="d-flex justify-content-between">
                            <div className="card insurancecard">
                              <div
                                className="d-flex flex-column align-items-start"
                                style={{ minHeight: "30px" }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "14px",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  The insurance has a type of '{insurance.type}'
                                  and marked with a status of '
                                  {insurance.status}'
                                </p>
                              </div>
                              <span className="text-muted insuranceDate">
                                {getFormattedDate(insurance.cdt)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ))}
              </div>
            ) : (
              ""
            )}

            {viewInsurance && (
              <div className="type-fields p-3 d-flex justify-content-between">
                {!toggleBasic && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => insuranceButtonClick("Basic")}
                    style={{ textTransform: "none" }}
                  >
                    Add Basic Info
                  </Button>
                )}
              </div>
            )}

            {showModal ? (
              <Components.PrescriptionLayoutModel
                data={data}
                uploadedImages={insuranceLists}
                onModalClose={(event) => closeModal(event)}
              />
            ) : (
              ""
            )}
            {selectedType ? (
              // <div className='modalmn'>

              <Components.InsuranceProcedureInput
                insurancetype={selectedType}
                insurancelists={insuranceLists}
                patientInfo={patientInfo}
                mode={mode}
                //patientProcedureList={patientProcedureList}
                onModalClose={(event) => closeModal(event)}
              />
            ) : (
              // </div>

              ""
            )}
          </div>
        </Card>
      </main>
    </>
  );
}

export default InsuranceProcedureListing;
