import React, { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../core/actions";
import { tokenActions } from "../../core/actions";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { DialogContent } from "@mui/material";

function LayoutHeader(props) {
  const [patientTokenList, setPatientTokenList] = useState();
  const [markCompleteModal, setmarkCompleteModal] = useState(false);
  const [visitCompleteModal, setVisitCompleteModal] = useState(false);
  const [soIdnullModal, setSoIdnullModal] = useState(false);
  const floorsList = useSelector(
    (state) => state.patientDetailsState.floorsList
  );
  const dispatch = useDispatch();
  const tokenlist = useSelector((state) => state.patientDetailsState.tokenlist);
  // console.log("token is",tokenlist);
  useEffect(() => {
    console.log("props in layout header:", props);
    console.log("props in layout headers:", props.selectedPatient);
    console.log("floorsList", floorsList);
    console.log("tokenlist in layout header", tokenlist);
  }, []);

  const closeModal = () => {
    props.onModalClose();
  };
  const allPatientsList = useSelector(
    (state) => state.patientDetailsState.patientsList
  );
  const handleFloorChange = (id) => {
    let data = {};
    console.log("id : ", id);
    data["floorId"] = id;
    data["type"] = "mypatient";
    dispatch(
      patientActions.ListPatients(data, (resCb) => {
        if (resCb) {
          console.log("response is: ", resCb);
          console.log("Diagnosis Master API Response:", allPatientsList);
        }
      })
    );
  };

  const handlemarkCompleteModal = (event) => {
    setmarkCompleteModal(true);
  };

  const moveToBilling = (event) => {
    setVisitCompleteModal(true);
  };

  const closeProcess = (event) => {
    setSoIdnullModal(true);
  };
  const soIdCheck = (event) => {
    props.onPatientBasic();
    let data = {};
    data["patientId"] = props.selectedPatient.visit.patientId;
    data["visitId"] = props.selectedPatient.visit.id;
    dispatch(
      patientActions.soIdCheck(data, (resCb) => {
        if (resCb) {
          console.log("soIdCheckresponse response", resCb);
          if (resCb.keyword && resCb.keyword == "soIdpresent") {
            console.log("keyword is", resCb.keyword);
            moveToBilling();
          } else {
            console.log("keyword is", resCb.keyword);
            closeProcess();
          }
        }
      })
    );
  };

  const handleopenDeleteModal = () => {
    props.onPatientBasic();
    setmarkCompleteModal(!markCompleteModal);
  };

  const closeVisitCompleteModal = () => {
    props.onPatientBasic();
    console.log("Button clicked");
    setVisitCompleteModal(!visitCompleteModal);
  };

  const closesoIdnullModal = () => {
    props.onPatientBasic();
    console.log("Button clicked");
    setSoIdnullModal(!soIdnullModal);
  };
  const tokenComplete = () => {
    //  var ServerResVal = ServerRes;
    props.onPatientBasic();
    console.log("button clicked");
    console.log("props in layout header:", props);
    let visitId = props.selectedPatient.visit.id;
    var patientId = props.selectedPatient.visit.patientId;
    console.log("visit id:", visitId);
    dispatch(
      tokenActions.completeToken(patientId, visitId, (resCb) => {
        if (resCb) {
          console.log("tokenresponse response", resCb);
          if (resCb.successMessages) {
            console.log("tokenresponse response", resCb.successMessages);
            dispatch(patientActions.tokenList(resCb.patientresp));
            console.log("tokenlist in layout header", tokenlist);
            handleopenDeleteModal();
          } else {
            handleopenDeleteModal();
          }
        }
      })
    );
  };

  const visitComplete = () => {
    // props.onPatientBasic();
    let data = {};
    data["patientId"] = props.selectedPatient.visit.patientId;
    data["visitId"] = props.selectedPatient.visit.id;
    data["dischargeDate"] = "";
    dispatch(
      patientActions.moveToBilling(data, (resCb) => {
        if (resCb) {
          console.log("tokenresponse response", resCb);
          if (resCb.successMessages) {
            console.log("tokenresponse response", resCb.successMessages);
            props.onPatientBasic();
            dispatch(patientActions.tokenList(props.onPatientBasic()));
            closeVisitCompleteModal();
          } else {
            closeVisitCompleteModal();
            props.onPatientBasic();
          }
        }
      })
    );
  };

  const Billclearstatus = () => {
    // props.onPatientBasic();
    let data = {};
    data["patientId"] = props.selectedPatient.visit.patientId;
    data["visitId"] = props.selectedPatient.visit.id;
    dispatch(
      patientActions.billclearstatus(data, (resCb) => {
        if (resCb) {
          closesoIdnullModal();
        }
      })
    );
  };

  return (
    <>
      <div className="pres-header">
        {/*   <Col md="3"></Col> */}
        <div
          className={
            props.basic ? "pres-layout-basic-info" : "list-layout-header"
          }
        >
          <div className="main-content-container container-fluid p-0">
            <div className="mb-12 col-md-12 hpcol bg menu-head">
              <div className="blog-comments__item d-flex cpointer  p-2 menu-content">
                <div className="" onClick={(event) => props.onPatientBasic()}>
                  <div className="blog-comments__content layout-head-content">
                    <div className="blog-comments__meta text-mutes d-flex img-name justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="patient-img">
                          {props.selectedPatient.imgurl ? (
                            <img
                              className="w-100 h-100 img-disp"
                              src={props.selectedPatient.imgurl}
                            ></img>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className="text-secondary pname px-2"
                          style={{
                            textTransform: "capitalize",
                            margin: "right",
                          }}
                        >
                          {props.selectedPatient.fname}
                          <small>
                            {props.selectedPatient &&
                            props.selectedPatient.id != undefined
                              ? "(" + props.selectedPatient.id + ")"
                              : ""}
                          </small>
                        </div>

                        {props && props.lab_admin !== true ? (
                          <>
                            {tokenlist &&
                            tokenlist.tokenNumber &&
                            tokenlist.visit &&
                            tokenlist.visit.type == "OutPatient" ? (
                              <div
                                className="text-secondary pname px-2"
                                style={{ margin: "left" }}
                              >
                                <button
                                  id="we"
                                  class="btn btn-primary"
                                  onClick={handlemarkCompleteModal}
                                  type="button"
                                >
                                  {" "}
                                  Mark completion
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            {tokenlist &&
                            tokenlist.visit &&
                            tokenlist.visit.internalStatus &&
                            tokenlist.visit.internalStatus == "booked" &&
                            tokenlist.visit.type == "OutPatient" ? (
                              <div
                                className="text-secondary pname px-2"
                                style={{ margin: "left" }}
                              >
                                <button
                                  id="we"
                                  class="btn btn-primary ml-2"
                                  onClick={soIdCheck}
                                  type="button"
                                >
                                  {" "}
                                  Visit Completion
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="d-flex flex-column">
                        <small
                          className="text-muted"
                          style={{ fontWeight: "500" }}
                        >
                          {props.selectedPatient.room &&
                          props.selectedPatient.room.id != 0 ? (
                            <span>
                              Room Name: {props.selectedPatient.room.roomName}
                            </span>
                          ) : (
                            ""
                          )}
                        </small>
                        <small
                          className="text-muted"
                          style={{ fontWeight: "500" }}
                        >
                          {props.selectedPatient &&
                          props.selectedPatient.phno != null ? (
                            <span>Phone No: {props.selectedPatient.phno}</span>
                          ) : (
                            ""
                          )}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* <div className="blog-comments__content menu-head-icon">
                                  <div className="blog-comments__meta text-mutes">
                                      <div className="text-secondary pname">
                                      {floorsList && floorsList.length > 0 ?
                                        <FormSelect onChange={event => { handleFloorChange(event.target.key) }}>
                                            {floorsList && floorsList.map((floor,index)=>(
                                                <option value={floor.name} key={index} id={floor.id}>{floor.name}</option>
                                            ))}
                                        </FormSelect>
                                        :''}
                                      </div>
                                  </div>
                                </div>  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="Mark_Completion"
        open={markCompleteModal}
        toggle={handlemarkCompleteModal}
      >
        <DialogTitle>Mark Completion</DialogTitle>
        <DialogContent>Do you want to complete the token</DialogContent>
        <DialogActions style={{ justifyContent: `space-between` }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={handleopenDeleteModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={tokenComplete}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="Visit_Completion"
        open={visitCompleteModal}
        toggle={closeVisitCompleteModal}
      >
        <DialogTitle>Visit Completion</DialogTitle>
        <DialogContent>Do you want to complete the consultation</DialogContent>
        <DialogActions style={{ justifyContent: `space-between` }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={closeVisitCompleteModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={visitComplete}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="soIdnullModal_Visit_Completion1"
        open={soIdnullModal}
        toggle={closesoIdnullModal}
      >
        <DialogTitle>Visit Completion</DialogTitle>
        <DialogContent>
          No service has been added to this user which means he has no payment
          to be made. Do you want to continue?
        </DialogContent>
        <DialogActions style={{ justifyContent: `space-between` }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={closesoIdnullModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={Billclearstatus}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LayoutHeader;
