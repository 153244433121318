import {
  PATIENT_DETAILS,
  SET_PATIENT_DETAILS,
  PATIENT_SAVE_SUCCESS,
  PATIENT_SAVE_FAILURE,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_FAILURE,
  PATIENT_VISIT_SAVE_SUCCESS,
  PATIENT_VISIT_SAVE_FAILURE,
  PROCEDURE_SAVE_SUCCESS,
  PROCEDURE_SAVE_FAILURE,
  PATIENT_SEARCH_FAILURE,
  VISIT_DETAILS,
  VISIT_DETAIL_FAILURE,
  SET_VISIT_ID,
  SET_PROCEDURE_ID,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  COMPLAINT_SAVE_SUCCESS,
  COMPLAINT_SAVE_FAILURE,
  REFERENCE_SAVE_SUCCESS,
  REFERENCE_SAVE_FAILURE,
  LIST_PATIENTS,
  LIST_PATIENTS_FAILURE,
  PATIENTS_INFO,
  LIST_FLOORS,
  PATIENTS_PROCEDURE_INFO,
  PATIENTS_LAST_VISITED_INFO,
  PATIENTS_INFO_FAILURE,
  SET_UPLOAD,
  LIST_VISTS,
  LIST_VISTS_FAILURE,
  SAVE_APPIONTMENT,
  SAVE_APPIONTMENT_FAILURE,
  SET_PAGE_HEADER,
  LIST_REVISTS,
  LIST_REVISTS_FAILURE,
  SAVE_REVISIT,
  SAVE_REVISIT_FAILURE,
  SAVE_VISIT,
  SAVE_VISIT_FAILURE,
  UPDATE_VA,
  UPDATE_VA_FAILURE,
  SAVE_DISPATCH,
  SAVE_DISPATCH_FAILURE,
  DISCHARGE_SUMMARY,
  MARK_FOR_DEL_FAILURE,
  MARK_FOR_DEL,
  INSURANCE_LIST,
  INSURANCE_PROCEDURE_SAVE_SUCCESS,
  INSURANCE_PROCEDURE_SAVE_FAILURE,
  INSURANCE_PROCEDURE_UPDATE_SUCCESS,
  INSURANCE_PROCEDURE_UPDATE_FAILURE,
  LIST_INSURANCE_SETTLEMENTS_FAILURE,
  LIST_INSURANCE_SETTLEMENTS,
  GET_DOCTOR_CALENDER,
  GET_DOCTOR_CALENDER_FAILURE,
  PATIENT_VISIT_LISTS,
  ONBOARD_SUCCESS,
  ONBOARD_FAILURE,
  CANCEL_DOCTOR_CALENDER,
  CANCEL_DOCTOR_CALENDER_FAILURE,
  PATIENT_ID,
  LIST_PRESCRIPTION,
  LIST_PRESCRIPTION_FAILURE,
  INSURANCE_STATUS,
  INSURANCE_STATUS_FAILURE,
  CURRENT_NOTES,
  PATIENT_SECTION,
  LIST_PATIENTS_FOR_DOCTORS,
  LIST_PATIENTS_FOR_DOCTORS_FAILURE,EHRID,
  E_PRISCIPTION,CARD_PAYMENT,CASH_PAYMENT,UPI_PAYMENT,TOKENLIST
} from "../../shared/constants";
import { patientServices } from "../services";
import patients from "../../shared/config/patients.json";
import floorsList from "../../shared/config/Floors.json";
import patientLastVisited from "../../shared/config/PatientVisited.json";

export function getAllPatientInfo(searchText, resCb) {
  console.log("SearchText,Action", searchText);
  return (dispatch) => {
    console.log("SearchText,Action", searchText);
    patientServices.getAllPatientInfo(searchText).then(
      (user) => {
        console.log("Res", user);
        dispatch(success(PATIENT_DETAILS, user.data));
        resCb(user.data);
      },
      (error) => {
        dispatch(failure(PATIENT_SEARCH_FAILURE, error.toString()));
        resCb(error.toString);
      }
    );
  };
}
export function addPatient(data,flag,admissionDateTime, resCb) {
    return (dispatch) => {
        patientServices.addPatient(data,flag,admissionDateTime)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(PATIENT_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function demoCon(resCb) {
    return (dispatch) => {
        patientServices.demoCon()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(PATIENT_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function updatePatient(data,convert,admissionDateTime, resCb) {
    return (dispatch) => {
        patientServices.updatePatient(data,convert,admissionDateTime)
            .then(
                user => { 
                    console.log("RESult",user)
                    dispatch(success(PATIENT_UPDATE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(PATIENT_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function addVisit(data, resCb) {
    return (dispatch) => {
        patientServices.addVisit(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(PATIENT_VISIT_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(PATIENT_VISIT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function addProcedure(data, resCb) {
    return (dispatch) => {
        patientServices.addProcedure(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(PROCEDURE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(PROCEDURE_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getVisitInfo(resCb) {
    return (dispatch) => {
        patientServices.getVisitInfo()
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(VISIT_DETAILS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(VISIT_DETAIL_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function uploadAttachments(fileData,resCb) {
    return dispatch => {
        patientServices.uploadAttachments(fileData)
            .then(
                user => { 
                    console.log("Upload-File",user)
                    dispatch(success(FILE_UPLOAD_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(FILE_UPLOAD_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addComplaint(data, resCb) {
    return (dispatch) => {
        patientServices.addComplaint(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(COMPLAINT_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(COMPLAINT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addReference(data, resCb) {
    return (dispatch) => {
        patientServices.addReference(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(REFERENCE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(REFERENCE_SAVE_FAILURE, error.toString()));
                    resCb(error.toString);
                }
            );
    };
}

export function ListPatients(data,resCb) {
     return (dispatch) => {
        console.log("SearchText,Action",data)
        patientServices.ListPatients(data)
            .then(
                Resp => { 
                    console.log("pateint list Res",Resp)
                    dispatch(success(LIST_PATIENTS_FOR_DOCTORS, Resp.patient));
                    resCb(Resp);
                   
                },
                error => {
                    dispatch(failure(LIST_PATIENTS_FOR_DOCTORS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    }; 
}

export function ListVisits(data,resCb) {
      return (dispatch) => {
         console.log("SearchText,Action",data)
         patientServices.ListVisits(data)
             .then(
                 visit => { 
                     console.log("Res",visit)
                     console.log("visit %",visit)
                     dispatch(success(LIST_VISTS, visit));
                     resCb(visit);
                 },
                 error => {
                     dispatch(failure(LIST_VISTS_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }

 export function getInsuranceStatus(visitIdList,resCb) {
     console.log("visit id in action method:",visitIdList)
      return (dispatch) => {
       
         patientServices.getInsuranceStatus(visitIdList)
             .then(
                 list => { 
                     console.log("Res",list)
                     dispatch(success(INSURANCE_STATUS, list));
                     resCb(list);
                 },
                 error => {
                     dispatch(failure(INSURANCE_STATUS_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }

 export function getActionList(visitIdList,resCb) {
     console.log("visit id in action method:",visitIdList)
      return (dispatch) => {
       
         patientServices.getActionList(visitIdList)
             .then(
                 actionlist => { 
                     console.log("Res",actionlist)
                     dispatch(success(INSURANCE_STATUS, actionlist));
                     resCb(actionlist);
                 },
                 error => {
                     dispatch(failure(INSURANCE_STATUS_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }

 export function ListRevisits(date1,date2,searchFollowUps,resCb) {
      return (dispatch) => {
         console.log("SearchText,Action",date1,date2,searchFollowUps)
         patientServices.ListRevisits(date1,date2,searchFollowUps)
             .then(
                 revisit => { 
                     console.log("Res",revisit)
                     dispatch(success(LIST_REVISTS, revisit));
                     resCb(revisit);
                 },
                 error => {
                     dispatch(failure(LIST_REVISTS_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }

 export function addRevisit(data,resCb) {
      return (dispatch) => {
         console.log("SearchText,Action",data)
         patientServices.addRevisit(data,resCb)
             .then(
                patient => { 
                     console.log("Res",patient)
                     dispatch(success(SAVE_REVISIT, patient));
                     resCb(patient);
                 },
                 error => {
                     dispatch(failure(SAVE_REVISIT_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }



 export function followUpByDoctor(data,resCb) {
    return (dispatch) => {
       console.log("SearchText,Action",data)
       patientServices.followUpByDoctor(data,resCb)
           .then(
              patient => { 
                   console.log("Res",patient)
                   dispatch(success(SAVE_REVISIT, patient));
                   resCb(patient);
               },
               error => {
                   dispatch(failure(SAVE_REVISIT_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}

 export function updateVAStatus(data,resCb) {
      return (dispatch) => {
         console.log("SearchText,Action",data)
         patientServices.updateVAStatus(data,resCb)
             .then(
                resp => { 
                     console.log("Res",resp)
                     dispatch(success(UPDATE_VA, resp));
                     resCb(resp);
                 },
                 error => {
                     dispatch(failure(UPDATE_VA_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }

 export function reAddVisit(data,resCb) {
      return (dispatch) => {
         console.log("SearchText,Action",data)
         patientServices.reAddVisit(data,resCb)
             .then(
                patient => { 
                     console.log("Res",patient)
                     dispatch(success(SAVE_VISIT, patient));
                     resCb(patient);
                 },
                 error => {
                     dispatch(failure(SAVE_VISIT_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }

 export function addAppointment(data,type,resCb) {
      return (dispatch) => {
         console.log("SearchText,Action",data)
         patientServices.addAppointment(data,type,resCb)
             .then(
                patient => { 
                     console.log("Res",patient)
                     dispatch(success(SAVE_APPIONTMENT, patient));
                     resCb(patient);
                 },
                 error => {
                     dispatch(failure(SAVE_APPIONTMENT_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }

 export function leaveCalender(data,gvid,date,resCb){
    return (dispatch) => {
        console.log("leaveCalender",data)
        patientServices.leaveCalender(data,gvid,date,resCb)
        .then(
            user => { 
                console.log("Res",user)
                resCb(user)
            },
            error => {
               resCb(error.toString);
            }
        );
    }; 
 }

 export function updateleaveCalender(gvid,date,resCb){
    return (dispatch) => {
        patientServices.updateleaveCalender(gvid,date,resCb)
        .then(
            user => { 
                console.log("Res",user)
                resCb(user)
            },
            error => {
               resCb(error.toString);
            }
        );
    }; 
 }

 export function getleaveCalender(gvid,date,resCb){
    return (dispatch) => {
        patientServices.getleaveCalender(gvid,date,resCb)
        .then(
            user => { 
                console.log("Res",user)
                resCb(user)
            },
            error => {
               resCb(error.toString);
            }
        );
    }; 
 }

export function listFloors(resCb){
    console.log("SearchText,Action",patients)
   // const url = basePath + ""
    return (dispatch) => {
        dispatch({ type: LIST_FLOORS, payload: floorsList.floorsList });   
        resCb(floorsList.floorsList)
    }
}

export function getPatientProfile(patientId,resCb){
    console.log("patientBasic,Action",patientId)
return (dispatch) => {
        console.log("SearchText,Action",patientId)
        patientServices.getPatientProfile(patientId)
            .then(
                user => { 
                    console.log("Response in getPatientProfile",user)
                    dispatch(success(PATIENTS_INFO, user.patientresp));
                    resCb(user.patientresp);
                },
                error => {
                    dispatch(failure(PATIENTS_INFO_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    }; 
}

export function getPatientLasteVisited(patientId,resCb){
    console.log("patientBasic,Action",patientId)
   // const url = basePath + ""
    return (dispatch) => {
        console.log('patientBasic',patientLastVisited)
        dispatch({ type: PATIENTS_LAST_VISITED_INFO, payload: patientLastVisited});   
        resCb(patientLastVisited)
    }
     /* return (dispatch) => {
        console.log("SearchText,Action",searchText)
        patientServices.ListPatients(searchText)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(LIST_FLOORS, user.data));
                    resCb(user.data);
                },
                error => {
                    dispatch(failure(LIST_FLOORS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    }; */
}

export function uploadFile(fileUploadData, resCb) {
    return dispatch => {
        patientServices.fileUpload(fileUploadData)
            .then(
                user => { 
                    console.log("Upload-File",user)
                    dispatch(success(FILE_UPLOAD_SUCCESS, user));
                    resCb(user);
                    console.log('user: ', user);
                    console.log('rescb: ', resCb);
                },
                error => {
                    dispatch(failure(FILE_UPLOAD_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function getrevisitbyId(patientId, visitId,resCb) {
    return (dispatch) => {
       console.log("SearchText,Action",patientId, visitId)
       patientServices.getrevisitbyId(patientId, visitId,resCb)
           .then(
              patient => { 
                   console.log("Res",patient)
                   dispatch(success(SAVE_REVISIT, patient));
                   resCb(patient);
               },
               error => {
                   dispatch(failure(SAVE_REVISIT_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}
//const dispatch = useDispatch();
export function getPatientProcedureInfo(patientId, visitId, type, resCb) {
  // return (dispatch) => {
  //     dispatch({ type: PATIENTS_PROCEDURE_INFO, payload: procedure });
  //     resCb(procedure)
  // }
  console.log("patient action for rocedrue", patientId, visitId, type);
  return (dispatch) => {
    patientServices.getPatientProcedureInfo(patientId, visitId, type).then(
      (user) => {
        console.log(
          "getPatientProcedureInfo response value",
          user
        );
        if (user && user.patientresp) {
          dispatch(success(FILE_UPLOAD_SUCCESS, user.patientresp));
          dispatch(setEhrid(user.patientresp.ehrid));
          console.log("set patient resp from procedure", user.patientresp);
        }
        resCb(user);
      },
      (error) => {
        dispatch(failure(FILE_UPLOAD_FAILURE, error.toString()));
        resCb(error.toString);
      }
    );
  };
}

export function patientSearch(data,resCb){

    console.log("patient action for patientSearch",data)
    return dispatch => {
        patientServices.patientSearch(data)
            .then(
                resp => {
                    //console.log("getPatientProcedureInfo response value",resp)
                    if(resp?.patientListCollection){
                       // resp ['count'] = resp.patientListCollection.length;
                       // user ['message'] = user.messageStrip
                        //console.log("patient search api response : ", resp);
                       dispatch(success(LIST_PATIENTS, resp.patientListCollection));
                        console.log("set patient resp from procedure", resp);
                        resCb(resp);
                    }
                },
                error => {
                    
                   // dispatch(failure(LIST_VISTS_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function saveDischargeSummary(data,resCb) {
    return (dispatch) => {
       console.log("SearchText,Action",data)
       patientServices.saveDischargeSummary(data,resCb)
           .then(
              patient => { 
                   console.log("Res",patient)
                   dispatch(success(SAVE_DISPATCH, patient));
                   resCb(patient);
               },
               error => {
                   dispatch(failure(SAVE_DISPATCH_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}

export function saveDischargeDate(data,resCb) {
    return (dispatch) => {
       console.log("SearchText,Action",data)
       patientServices.saveDischargeDate(data,resCb)
           .then(
              patient => { 
                   console.log("Res",patient)
                   dispatch(success(SAVE_DISPATCH, patient));
                   resCb(patient);
               },
               error => {
                   dispatch(failure(SAVE_DISPATCH_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}

export function saveVisitAssignment(data,resCb) {
    return (dispatch) => {
       console.log("SearchText,Action",data)
       patientServices.saveVisitAssignment(data,resCb)
           .then(
              patient => { 
                   console.log("Res",patient)
                   dispatch(success(SAVE_DISPATCH, patient));
                   resCb(patient);
               },
               error => {
                   dispatch(failure(SAVE_DISPATCH_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}

export function updateDischargeSummary(data,resCb) {
    return (dispatch) => {
       console.log("SearchText,Action",data)
       patientServices.updateDischargeSummary(data,resCb)
           .then(
              patient => { 
                   console.log("Res",patient)
                   dispatch(success(SAVE_DISPATCH, patient));
                   resCb(patient);
               },
               error => {
                   dispatch(failure(SAVE_DISPATCH_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}

export function markForDel(data,resCb) {
    return (dispatch) => {
       console.log("SearchText,Action",data)
       patientServices.markForDel(data,resCb)
           .then(
              patient => { 
                   console.log("Res",patient)
                   dispatch(success(MARK_FOR_DEL, patient));
                   resCb(patient);
               },
               error => {
                   dispatch(failure(MARK_FOR_DEL_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}


export function getDischargeSummary(data, details, resCb) {
    console.log( " listZoomNotes   ",data);
    return (dispatch) => {
      console.log( " listZoomNotes   ",);
      patientServices.getDischargeSummary(data, details).then(
        (response) => {
          console.log( " listZoomNotes ReSult in actions", response);
          dispatch(success(DISCHARGE_SUMMARY, response));
          resCb(response);
        },
        (error) => {
          //dispatch(failure(ZOOM_NOTES_UPDATE_FAILURE, error.toString()));
          resCb(error.toString);
        }
      );
    };
  } 
  
  export function getVisitbyId(data, resCb) {
    console.log( " visit : ",data);
    return (dispatch) => {
      console.log( " listZoomNotes   ",);
      patientServices.getVisitbyId(data).then(
        (response) => {
          console.log( " listZoomNotes ReSult in actions", response);
          dispatch(success(DISCHARGE_SUMMARY, response));
          resCb(response);
        },
        (error) => {
          //dispatch(failure(ZOOM_NOTES_UPDATE_FAILURE, error.toString()));
          resCb(error.toString);
        }
      );
    };
  }

  export function getInsuranceList(data, resCb) {
    console.log( "insurance :",data);
    return (dispatch) => {
      patientServices.getInsuranceList(data).then(
        (response) => {
          console.log( "insuranceList", response);
          dispatch(success(INSURANCE_LIST, response));
          resCb(response);
        },
        (error) => {
          resCb(error.toString);
        }
      );
    };
  }

  export function addInsuranceProcedure(data, resCb) {
    return (dispatch) => {
        patientServices.addInsuranceProcedure(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(INSURANCE_PROCEDURE_SAVE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(INSURANCE_PROCEDURE_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function updateInsuranceProcedure(data, resCb) {
    return (dispatch) => {
        patientServices.updateInsuranceProcedure(data)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(INSURANCE_PROCEDURE_UPDATE_SUCCESS, user));
                    resCb(user);
                },
                error => {
                    dispatch(failure(INSURANCE_PROCEDURE_UPDATE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function ListInsuranceSettlements(data,resCb) {
      return (dispatch) => {
         patientServices.ListInsuranceSettlements(data)
             .then(
                 list => { 
                     console.log("Res",list)
                     dispatch(success(LIST_INSURANCE_SETTLEMENTS, list));
                     resCb(list);
                 },
                 error => {
                     dispatch(failure(LIST_INSURANCE_SETTLEMENTS_FAILURE, error.toString()));
                    resCb(error.toString);
                 }
             );
     }; 
 }
 
 export function EditInsuranceSettlements(data,resCb) {
    return (dispatch) => {
       patientServices.EditInsuranceSettlements(data)
           .then(
               list => { 
                   console.log("Res",list)
                   dispatch(success(LIST_INSURANCE_SETTLEMENTS, list));
                   resCb(list);
               },
               error => {
                   dispatch(failure(LIST_INSURANCE_SETTLEMENTS_FAILURE, error.toString()));
                  resCb(error.toString);
               }
           );
   }; 
}


  
  export function updateGroupId(data, resCb) {
    console.log( " visit : ",data);
    return (dispatch) => {
      console.log( " listZoomNotes   ",);
      patientServices.updateGroupId(data).then(
        (response) => {
          console.log( " listZoomNotes ReSult in actions", response);
          dispatch(success(DISCHARGE_SUMMARY, response));
          resCb(response);
        },
        (error) => {
          
          resCb(error.toString);
        }
      );
    };
  }


  export function getPatientProcedureByLastVisitDate(data,resCb){
    // return (dispatch) => {
    //     dispatch({ type: PATIENTS_PROCEDURE_INFO, payload: procedure });   
    //     resCb(procedure)
    // }
    console.log("patient action for rocedrue")
    return dispatch => {
        patientServices.getPatientProcedureByVisitDate(data)
            .then(
                user => { 
                    console.log("getPatientProcedureByLastVisitDate response value",user)
                    if(user && user.patientresp){
                       dispatch(success(FILE_UPLOAD_SUCCESS, user.patientresp));
                       console.log("set patient resp from PatientProcedureByLastVisitDate", user.patientresp);
                    }
                    resCb(user);
                },
                error => {
                    dispatch(failure(FILE_UPLOAD_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getVisitListById(visitId,type,resCb){
    // return (dispatch) => {
    //     dispatch({ type: PATIENTS_PROCEDURE_INFO, payload: procedure });   
    //     resCb(procedure)
    // }
    console.log("patient action for rocedrue")
    return dispatch => {
        patientServices.getVisitListById(visitId,type)
            .then(
                data => { 
                    console.log("getVisitListById response value",data)
                    if(data && data.patientresp){
                       //dispatch(success(VISIT_LIST_BYID, data.patientresp));
                       console.log("getVisitListById response", data.patientresp);
                      
                    }
                    resCb(data);
                },
                error => {
                   // dispatch(failure(FILE_UPLOAD_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getDoctorCalenderByDate(data,resCb){
    console.log("get doctor calender")
    return dispatch => {
        patientServices.getDoctorCalenderByDate(data)
            .then(
                user => { 
                    console.log("get doctor calender response value",user)
                    if(user && user.calenderresp){
                       dispatch(success(GET_DOCTOR_CALENDER, user.calenderresp));
                       console.log("set doctor calender resp from doctorCalenderByDate", user.calenderresp);
                    }
                    resCb(user);
                },
                error => {
                    dispatch(failure(GET_DOCTOR_CALENDER_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getDoctorCalenderForPatient(data,resCb){
    console.log("get doctor calender for patient")
    return dispatch => {
        patientServices.getDoctorCalenderForPatient(data)
            .then(
                user => { 
                    console.log("get doctor calender for patient response value",user)
                    if(user && user.calenderresp){
                       dispatch(success(GET_DOCTOR_CALENDER, user.calenderresp));
                       console.log("set doctor calender resp from doctorCalenderByDate", user.calenderresp);
                    }
                    resCb(user);
                },
                error => {
                    dispatch(failure(GET_DOCTOR_CALENDER_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function onboardPatient(data,type,admissionDateTime,resCb){
    console.log("onboardPatient data:",type)
    return dispatch => {
        patientServices.onboardPatient(data,type,admissionDateTime)
            .then(
                user => { 
                    console.log("patient onboarded",user)
                    if(user && user.calenderresp){
                       dispatch(success(ONBOARD_SUCCESS, user.calenderresp));
                    }
                    resCb(user);
                },
                error => {
                    dispatch(failure(ONBOARD_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function cancelDoctorCalenderByPatientId(data,resCb){
    // return (dispatch) => {
    //     dispatch({ type: PATIENTS_PROCEDURE_INFO, payload: procedure });   
    //     resCb(procedure)
    // }
    console.log("cancel doctor calender")
    return dispatch => {
        patientServices.cancelDoctorCalenderByPatientId(data)
            .then(
                user => { 
                    console.log("cancel doctor calender response value",user)
                    if(user && user.calenderresp){
                       dispatch(success(CANCEL_DOCTOR_CALENDER, user.calenderresp));
                       console.log("cancel doctor calender resp from doctorCalenderByPatientId", user.calenderresp);
                    }
                    resCb(user);
                },
                error => {
                    dispatch(failure(CANCEL_DOCTOR_CALENDER_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function moveToBilling(data, resCb) {
    console.log( "insurance :",data);
    return (dispatch) => {
      patientServices.moveToBilling(data).then(
        (response) => {
          console.log( "insuranceList", response);
          dispatch(success(INSURANCE_LIST, response));
          resCb(response);
        },
        (error) => {
            console.log("error string",error);
          resCb(error.toString);
        }
      );
    };
  }


  export function soIdCheck(data, resCb) {
    console.log( "insurance :",data);
    return (dispatch) => {
      patientServices.soIdCheck(data).then(
        (response) => {
          console.log( "insuranceList", response);
          dispatch(success(INSURANCE_LIST, response));
          resCb(response);
        },
        (error) => {
            console.log("error string",error);
          resCb(error.toString);
        }
      );
    };
  }

  export function billclearstatus(data, resCb) {
    console.log( "insurance :",data);
    return (dispatch) => {
      patientServices.billclearstatus(data).then(
        (response) => {
          console.log( "insuranceList", response);
          dispatch(success(INSURANCE_LIST, response));
          resCb(response);
        },
        (error) => {
            console.log("error string",error);
          resCb(error.toString);
        }
      );
    };
  }

  export function roomDealocate(data, resCb) {
    console.log( "insurance :",data);
    return dispatch => {
      patientServices.roomDealocate(data,resCb).then(
        (user) => {
          console.log( "insuranceList", user);
          dispatch(success(INSURANCE_LIST, user));
          resCb(user);
        },
        (error) => {
            console.log("error string",error);
            dispatch(failure(CANCEL_DOCTOR_CALENDER_FAILURE, error.toString()));
          resCb(error.toString);
        }
      );
    };
  }

  export function registrationFees(customerId,totalfees,visitId,patientId,type,data, resCb) {
    //console.log( "insurance :",data);
    return (dispatch) => {
      patientServices.registrationFees(customerId,totalfees,visitId,patientId,type,data).then(
        (response) => {
          console.log( "insuranceList", response);
          dispatch(success(INSURANCE_LIST, response));
          resCb(response);
        },
        (error) => {
            console.log("error string",error);
          resCb(error.toString);
        }
      );
    };
  }

  export function totalFees(resCb) {
    //console.log( "insurance :",data);
    return (dispatch) => {
      patientServices.totalFees().then(
        (response) => {
          console.log( "insuranceList", response);
          dispatch(success(INSURANCE_LIST, response));
          resCb(response);
        },
        (error) => {
            console.log("error string",error);
          resCb(error.toString);
        }
      );
    };
  }



function setPatientVisitList(value){
    console.log("PATIENT_VISIT_LISTS Obtained:",value);
    return { type: PATIENT_VISIT_LISTS, payload : value };
}

function success(type, data){
    return { type: type, payload : data }
}
// function request(type, value) {
//     return { type: type, payload: value };
// }
function failure(type, value) {
    return { type: type, payload : value };
}
function setPatientDetails(value) {
    return { type: SET_PATIENT_DETAILS, payload : value };
}
function setVisitId(value){
    return {type:SET_VISIT_ID, payload: value}
}
function setProcedureId(value){
    return {type:SET_PROCEDURE_ID, payload: value}
}
function setUploadedFiles(value){
    console.log(" SET_UPLOAD  :", value)
    return {type:SET_UPLOAD, payload: value}
}
export function setPageHeader(value){
    console.log("Admin SET_PAGE_HEADER : ",value)
    return {type: SET_PAGE_HEADER, payload: value}
}

export function setSelectedPatientId(value){
    console.log("PATIENT_ID : ",value)
    return {type: PATIENT_ID, payload: value}
}

export function setSelectedNotes(value){
    console.log("CURRENT_NOTES : ",value)
    return {type: CURRENT_NOTES, payload: value}
}
export function ListPrescriptions(data,patientEhrid, resCb) {
  return (dispatch) => {
    patientServices.ListPrescriptions(data,patientEhrid).then(
      (list) => {
        console.log("Res", list);
        dispatch(success(LIST_PRESCRIPTION, list));
        resCb(list);
      },
      (error) => {
        dispatch(failure(LIST_PRESCRIPTION_FAILURE, error.toString()));
        resCb(error.toString);
      }
    );
  };
}
export function setEhrid(value) {
    console.log("ehridddd",value)
  return { type: EHRID, payload: value };
}
export function setForm(value) {
  console.log("setFormmj",value)
return { type: E_PRISCIPTION, payload: value };
}

export function prescriptions(ids,resCb){

    console.log("patientBasic,Action",ids)
    // const url = basePath + ""
     return (dispatch) => {
         
         resCb([
             { serialNo: 1, medicineName: 'dolo', units: '650' ,dosage:'1+0+1'},
             { serialNo: 2, medicineName: 'dolodf gdfdfdf', units: '650',dosage :'1+0+1'},
             { serialNo: 2, medicineName: 'dolodf', units: '650',dosage :'1+0+1'},
             
           ])

    };
}
export function setPage(value){
    return {type: PATIENT_SECTION, payload: value}
}
function cardPayment(value) {
    console.log("action file set token",value);
    return { type: CARD_PAYMENT, payload : value };
  }
  function upiPayment(value) {
    console.log("action file set token",value);
    return { type: UPI_PAYMENT, payload : value };
  }
  function cashPayment(value) {
    console.log("action file set token",value);
    return { type: CASH_PAYMENT, payload : value };
  }
  function tokenList(value) {
    console.log("action file set token",value);
    return { type: TOKENLIST, payload : value };
  }
  
  export function getVouchers(erpCustId, resCb) {
    return (dispatch) => {
        patientServices.getVouchers(erpCustId)
            .then(
                user => { 
                    console.log("Res fvjvhd ",user)
                    resCb(user);
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                  // resCb(error.toString);
                }
            );
    };
}

export function getRedeemedVouchers(erpCustId, resCb) {
    return (dispatch) => {
        patientServices.getRedeemedVouchers(erpCustId)
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user);
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}


export function redeemVoucher(visitId,pateintId,voucherInfo, resCb) {
    return (dispatch) => {
        patientServices.redeemVoucher(visitId,pateintId,voucherInfo)
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user)
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function getRedeemeedVoucher(pateintId, resCb) {
    return (dispatch) => {
        patientServices.getRedeemeedVoucher(pateintId)
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user)
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}
export function addToProcedure(data, resCb) {
    console.log("add to procedurrun")
    return (dispatch) => {
        patientServices.redeemVoucher(data)
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user)
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}


export function getAppointedPatientDetails(pateintIds, resCb) {
    return (dispatch) => {
        patientServices.getAppointedPatientDetails(pateintIds)
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user)
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function UpdateToInSitePatient(visitId,visitDate, resCb) {
    return (dispatch) => {
        patientServices.UpdateToInSitePatient(visitId,visitDate)
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user)
                },
                error => {
                   resCb(error.toString);
                }
            );
    };
}


export function getRegistrationPDF(pateintIds, resCb) {
    return (dispatch) => {
        patientServices.getRegistrationPDF(pateintIds)
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user)
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function checkScanner(resCb) {
    return (dispatch) => {
        patientServices.checkScanner()
            .then(
                user => { 
                    console.log("Res",user)
                    resCb(user)
                },
                error => {
                //    dispatch(failure(PATIENT_SAVE_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function uploadToken(data, resCb) {
    console.log( "insurance :",data);
    return dispatch => {
      patientServices.uploadToken(data,resCb).then(
        (user) => {
          console.log( "insuranceList", user);
          dispatch(success("Auth Token", user));
          resCb(user);
        },
        (error) => {
            console.log("error string",error);
            dispatch(failure("Auth Token failure", error.toString()));
          resCb(error.toString);
        }
      );
    };
  }

  export function scanDocument(authToken, resCb) {
    console.log( "auth token is :",authToken);
    return dispatch => {
      patientServices.scanDocument(authToken,resCb).then(
        (user) => {
          dispatch(success("Scan Document success", user));
          resCb(user);
        },
        (error) => {
            console.log("error string",error);
            dispatch(failure("Scan Document failure", error.toString()));
          resCb(error.toString);
        }
      );
    };
  }

  export function deletePackageService(packageServiceId, resCb) {
    return dispatch => {
      patientServices.deletePackageService(packageServiceId,resCb).then(
        (user) => {
          dispatch(success('DELETE_PACKAGESERVICE_SUCCESS', user));
          resCb(user);
        },
        (error) => {
            console.log("error string",error);
            dispatch(failure('DELETE_PACKAGESERVICE_FAILURE', error.toString()));
          resCb(error.toString);
        }
      );
    };
  }

const pActions = {
    getAllPatientInfo,
    setPatientDetails,
    addPatient,
    updatePatient,
    addVisit,
    addProcedure,
    getVisitInfo,
    setVisitId,
    setProcedureId,
    uploadAttachments,
    addComplaint,
    addReference,
    ListPatients,
    getPatientProcedureInfo,
    listFloors,
    getPatientProfile,
    getPatientLasteVisited,
    uploadFile,
    setUploadedFiles,
    ListVisits,
    ListRevisits,
    addRevisit,
    reAddVisit,
    addAppointment,
    leaveCalender,
    updateleaveCalender,
    getleaveCalender,
    setPageHeader,
    patientSearch,
    updateVAStatus,
    saveDischargeSummary,
    updateDischargeSummary,
    getDischargeSummary,
    getVisitbyId,
    updateGroupId,
    markForDel,
    getPatientProcedureByLastVisitDate,
    getInsuranceList,
    addInsuranceProcedure,
    updateInsuranceProcedure,
    getVisitListById,
    setPatientVisitList,
    getDoctorCalenderByDate,
    ListInsuranceSettlements,
    EditInsuranceSettlements,
    onboardPatient,
    cancelDoctorCalenderByPatientId,
    prescriptions,
    setSelectedPatientId,
    ListPrescriptions,
    moveToBilling,
    roomDealocate,
    getInsuranceStatus,
    getActionList,
    setSelectedNotes,
    setPage,
    setEhrid,
    setForm,
    demoCon,
    followUpByDoctor,
    registrationFees,
    cashPayment,
    cardPayment,
    upiPayment,
    totalFees,
    tokenList,
    getVouchers,
    redeemVoucher,
    getAppointedPatientDetails,
    getRegistrationPDF,
    getRedeemedVouchers,
    checkScanner,
    uploadToken,
    scanDocument,
    getrevisitbyId,
    deletePackageService,
    soIdCheck,
    billclearstatus,
    saveVisitAssignment,
    getDoctorCalenderForPatient,
    saveDischargeDate,
    UpdateToInSitePatient
}

export default pActions;
